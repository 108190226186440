import React, { useState, useEffect } from 'react';
import { 
  Box, 
  FormControl, 
  FormLabel, 
  Input, 
  Button, 
  Heading, 
  VStack, 
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { saveAs } from 'file-saver';
import ProgressBar from './ProgressBar';

function TranscriptDownloader() {
  // State definitions first
  const [url, setUrl] = useState('');
  const [hearingName, setHearingName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('');
  const [completedTranscripts, setCompletedTranscripts] = useState([]);
  const [diarizedData, setDiarizedData] = useState(null);
  const [ws, setWs] = useState(null);
  const toast = useToast();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isFetchingHistory, setIsFetchingHistory] = useState(false);
  const [isValidData, setIsValidData] = useState(false);

  // Define fetchCompletedTranscripts before it's used
  const fetchCompletedTranscripts = async () => {
    setIsFetchingHistory(true);
    setError(null);
    
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      console.log('Fetching from URL:', `${apiUrl}/api/hearings`);
      
      const response = await fetch(`${apiUrl}/api/hearings`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Raw hearings data:', data);
      
      if (Array.isArray(data)) {
        const completedHearings = data
          .filter(hearing => hearing.status === 'completed')
          .map(hearing => ({
            ...hearing,
            processing_completed_at: hearing.processing_completed_at || new Date().toISOString() // Fallback for new transcripts
          }))
          .sort((a, b) => new Date(b.processing_completed_at) - new Date(a.processing_completed_at))
          .slice(0, 10);
        
        console.log('Filtered completed hearings:', completedHearings);
        setCompletedTranscripts(completedHearings);
      }
      
    } catch (error) {
      console.error('Error fetching transcripts:', error);
      setError(error.message);
      toast({
        title: "Error",
        description: `Failed to load transcript history: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsFetchingHistory(false);
    }
  };

  // Then define setupWebSocket that uses it
  const setupWebSocket = (videoId) => {
    // Close existing connection if any
    if (ws) {
      ws.close();
      setWs(null);
    }
    
    // Clean the videoId by removing any URL parameters
    const cleanVideoId = videoId.split('&')[0];
    const wsUrl = `wss://api.advocaide.app/ws/${cleanVideoId}`;
    console.log('Setting up WebSocket connection to:', wsUrl);
    
    try {
      const newWs = new WebSocket(wsUrl);

      newWs.onopen = () => {
        console.log('WebSocket connection established');
        setStatus('Connected to server');
      };

      newWs.onmessage = (event) => {
        try {
          console.log('Received WebSocket message:', event.data);
          const data = JSON.parse(event.data);
          
          if (data.status === 'error') {
            setError(data.message);
            setStatus('Error occurred during processing');
            newWs.close();
            // Refresh the transcript list to show updated status
            fetchCompletedTranscripts();
          } else if (data.status === 'completed' && data.data) {
            setStatus('Processing complete!');
            setProgress(100);
            setDiarizedData(data.data);
            newWs.close();
            // Refresh the transcript list to show the new transcript
            fetchCompletedTranscripts();
          }
        } catch (error) {
          console.error('Error processing WebSocket message:', error);
        }
      };

      newWs.onerror = (error) => {
        console.error('WebSocket error:', error);
        setStatus('Error connecting to server');
      };

      setWs(newWs);
    } catch (error) {
      console.error('Error setting up WebSocket:', error);
      setStatus('Error setting up connection');
    }
  };

  // Initial fetch of completed transcripts
  useEffect(() => {
    // Only fetch once when component mounts
    fetchCompletedTranscripts();
  }, []); // Empty dependency array

  useEffect(() => {
    if (diarizedData) {
      window.currentDiarization = diarizedData;
      console.log('Diarization data available in window.currentDiarization');
    }
  }, [diarizedData]);

  useEffect(() => {
    return () => {
      // Cleanup WebSocket on component unmount
      if (ws) {
        ws.close();
        setWs(null);
      }
    };
  }, [ws]);

  // Define all handler functions
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setError(null);
    setProgress(0);
    setStatus('Starting process...');

    try {
      const videoId = url.split('v=')[1] || url.split('/').pop();
      const apiUrl = process.env.REACT_APP_API_URL;
      
      // Make the API call first
      const response = await fetch(`${apiUrl}/api/diarized-transcript`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url: url,
          hearingName: hearingName || 'Untitled Hearing'
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to get diarized transcript: ${errorText}`);
      }

      const data = await response.json();
      
      if (data.status === 'completed') {
        setDiarizedData(data.data);
        setStatus('Retrieved existing transcript');
        setProgress(100);
      } else {
        setupWebSocket(videoId);
      }

      // Handle speaker identification if needed
      if (data.status === 'completed' && !data.data?.speaker_map) {
        console.log('Getting speaker identification for completed transcript');
        const speakerResponse = await fetch(`${apiUrl}/api/finalized-transcript`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            url: url,
            hearingName: hearingName || 'Untitled Hearing'
          })
        });
        
        if (speakerResponse.ok) {
          const finalData = await speakerResponse.json();
          setDiarizedData(finalData);
        }
      }

      // After successful processing, refresh the completed transcripts
      await fetchCompletedTranscripts();

      toast({
        title: "Success",
        description: "Transcript processed successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

    } catch (err) {
      console.error('Error:', err);
      setError(err.message);
      toast({
        title: "Error",
        description: err.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const formatTranscriptForDownload = (diarizedTranscript, hearingInfo) => {
    if (!diarizedTranscript?.final_transcript) {
      throw new Error('No transcript data available');
    }

    // Get the speaker map or use empty object if not available
    const speakerMap = diarizedTranscript.speaker_map || {};
    
    // Format each segment using the same logic as renderSpeakerSegments
    const formattedSegments = diarizedTranscript.final_transcript
      .map(segment => {
        const speaker = speakerMap[segment.speaker] || segment.speaker;
        const timestamp = `[${Math.floor(segment.start)}s - ${Math.floor(segment.end)}s]`;
        return `${speaker}:\n${segment.text}\n${timestamp}\n`;
      })
      .join('\n');

    // Add header information
    const header = [
      `Hearing Name: ${hearingInfo.hearing_name || 'Untitled'}`,
      `Video ID: ${hearingInfo.video_id}`,
      `Date: ${new Date().toLocaleString()}`,
      '\nTranscript:\n'
    ].join('\n');

    return header + '\n' + formattedSegments;
  };

  const handleSaveReadableTranscript = () => {
    try {
      if (!diarizedData) {
        throw new Error('No transcript data available');
      }

      const videoId = url.split('v=')[1] || url.split('/').pop();
      
      const formattedTranscript = formatTranscriptForDownload(
        diarizedData,
        {
          hearing_name: hearingName || diarizedData.hearing_name,
          video_id: videoId
        }
      );

      const blob = new Blob([formattedTranscript], { type: 'text/plain' });
      saveAs(blob, `${diarizedData.hearing_name || 'transcript'}_${videoId}.txt`);
      
      toast({
        title: "Success",
        description: "Readable transcript saved!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Save error:', error);
      toast({
        title: "Save Failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleTranscriptSelect = async (videoId) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      console.log('Fetching transcript for video:', videoId);
      
      const response = await fetch(`${apiUrl}/api/hearings/${videoId}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        const text = await response.text();
        console.error('Error response:', text);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched hearing details:', data);

      // Update the URL and diarized data
      setUrl(`https://youtube.com/watch?v=${videoId}`);
      setDiarizedData(data.diarized_transcript);
      
      // Optional: scroll to the transcript section
      const transcriptSection = document.querySelector('[role="tabpanel"]');
      if (transcriptSection) {
        transcriptSection.scrollIntoView({ behavior: 'smooth' });
      }

    } catch (error) {
      console.error('Error fetching transcript:', error);
      toast({
        title: "Error",
        description: "Failed to load transcript",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleTranscriptDownload = async (hearing) => {
    setIsDownloading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/hearings/${hearing.video_id}/transcript`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      // Format the transcript using the same logic as display
      const formattedTranscript = formatTranscriptForDownload(
        data.diarized_transcript,
        {
          hearing_name: hearing.hearing_name,
          video_id: hearing.video_id
        }
      );

      // Download
      const blob = new Blob([formattedTranscript], { type: 'text/plain' });
      saveAs(blob, `${hearing.hearing_name || 'transcript'}_${hearing.video_id}.txt`);

      toast({
        title: "Success",
        description: "Transcript downloaded successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

    } catch (error) {
      console.error('Download error:', error);
      toast({
        title: "Download Failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDownloading(false);
    }
  };

  // Define render functions
  const renderSpeakerSegments = () => {
    if (!diarizedData?.final_transcript) return <Text>No speaker segments available</Text>;
    
    // Get the speaker map or use empty object if not available
    const speakerMap = diarizedData.speaker_map || {};
    
    return diarizedData.final_transcript.map((segment, index) => (
      <Box key={index} mb={4} p={2} borderLeft="2px" borderColor="brand.500" pl={4}>
        <Text fontWeight="bold" color="brand.500">
          {speakerMap[segment.speaker] || segment.speaker}
        </Text>
        <Text>{segment.text}</Text>
        <Text fontSize="sm" color="gray.500">
          {`${Math.floor(segment.start)}s - ${Math.floor(segment.end)}s`}
        </Text>
      </Box>
    ));
  };

  const renderRawTranscript = () => {
    if (!diarizedData?.raw_transcript) return <Text>No transcript available</Text>;
    return diarizedData.raw_transcript.map((segment, index) => (
      <Text key={index} mb={2}>
        {segment.text}
      </Text>
    ));
  };

  // Table display component
  const CompletedHearingsTable = () => {
    // Get only the 10 most recent hearings, sorted by completion date
    const recentHearings = completedTranscripts
      .sort((a, b) => new Date(b.processing_completed_at) - new Date(a.processing_completed_at))
      .slice(0, 10);

    return (
      <Box mt={8}>
        <Flex justify="space-between" align="center" mb={4}>
          <Heading as="h3" size="md">
            Recent Completed Transcripts
          </Heading>
          <Button
            size="sm"
            colorScheme="brand"
            onClick={fetchCompletedTranscripts}
            isLoading={isFetchingHistory}
          >
            Refresh List
          </Button>
        </Flex>
        
        {error && (
          <Alert status="error" mb={4}>
            <AlertIcon />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {isFetchingHistory ? (
          <Text>Loading transcript history...</Text>
        ) : recentHearings?.length ? (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Hearing Name</Th>
                <Th>Completed</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {recentHearings.map((hearing) => (
                <Tr key={hearing.video_id}>
                  <Td>{hearing.hearing_name || 'Untitled'}</Td>
                  <Td>
                    {hearing.processing_completed_at 
                      ? new Date(hearing.processing_completed_at).toLocaleDateString() 
                      : 'N/A'}
                  </Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme="brand"
                      leftIcon={<DownloadIcon />}
                      isLoading={isDownloading}
                      onClick={() => handleTranscriptDownload(hearing)}
                    >
                      Download Transcript
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Text>No completed transcripts available</Text>
        )}
      </Box>
    );
  };

  // Return the JSX
  return (
    <Box py={10} px={6}>
      {/* Form Section */}
      <Box textAlign="center" maxW="xl" mx="auto" mb={8}>
        <Heading as="h2" size="lg" mb={6}>
          Hearing Transcript Generator
        </Heading>
        
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} mb={6}>
            <FormControl isRequired>
              <FormLabel>YouTube URL</FormLabel>
              <Input
                type="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter YouTube URL"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Hearing Name</FormLabel>
              <Input
                type="text"
                value={hearingName}
                onChange={(e) => setHearingName(e.target.value)}
                placeholder="Enter Hearing Name"
              />
            </FormControl>
            <Button
              type="submit"
              colorScheme="brand"
              isLoading={isProcessing}
              loadingText="Processing..."
              width="full"
            >
              Fetch Transcript
            </Button>
          </VStack>
        </form>

        {/* Progress and status indicators */}
        {isProcessing && (
          <Box mt={4}>
            <ProgressBar progress={progress} />
            <Text mt={2}>{status}</Text>
          </Box>
        )}
      </Box>

      {/* Results Section */}
      <Box mt={8}>
        {/* Current Transcript Results */}
        {diarizedData && (
          <Box mb={8}>
            <Tabs index={tabIndex} onChange={setTabIndex}>
              <TabList>
                <Tab>Speaker Segments</Tab>
                <Tab>Raw Transcript</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {renderSpeakerSegments()}
                  {diarizedData?.final_transcript && (
                    <Button
                      mt={4}
                      colorScheme="brand"
                      leftIcon={<DownloadIcon />}
                      onClick={handleSaveReadableTranscript}
                    >
                      Download Transcript
                    </Button>
                  )}
                </TabPanel>
                <TabPanel>
                  {renderRawTranscript()}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        )}

        {/* Completed Transcripts Table - Always Show */}
        <CompletedHearingsTable />
      </Box>
    </Box>
  );
}

export default TranscriptDownloader;
